.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.loading-text {
  animation: fadeInOut 2s infinite;
}

.css-13cymwt-control {
  height: 58px !important;
}

.badge-label.bg-danger:before {
  border-right-color: #DC2626 !important;
}

.bg-danger {
  --vz-bg-opacity: 1;
  background-color: #DC2626 !important;
}

.badge-label.bg-primary:before {
  border-right-color: #2563EB !important;
}

.bg-primary {
  --vz-bg-opacity: 1;
  background-color: #2563EB !important;
}

.badge-label.bg-success:before {
  border-right-color: #16A34A !important;
}

.bg-success {
  --vz-bg-opacity: 1;
  background-color: #16A34A !important;
}

.badge-label.bg-warning:before {
  border-right-color: #FACC15 !important;
}

.bg-warning {
  --vz-bg-opacity: 1;
  background-color: #FACC15 !important;
}

.video-container {
  position: relative;
  width: 100%; /* lebar kontainer sebesar lebar layar atau elemen induknya */
  padding-top: 56.25%; /* rasio aspek 16:9; 9 / 16 = 0.5625 */
  overflow: hidden;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sc-ezrdqu .gODfSl {
  display: none !important;
}

.gODfSlv2 {
  display: flex;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
}

.cxFtRn {
  display: flex !important;
  flex: 1 1 auto !important;
  justify-content: flex-end !important;
  align-items: center !important;
  box-sizing: border-box !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
  width: 100% !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 13px !important;
  min-height: 56px !important;
  background-color: #FFFFFF !important;
  border-top-style: solid !important;
  border-top-width: 1px !important;
  border-top-color: rgba(0, 0, 0, .12) !important;
}